













































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapActions, mapMutations, MutationMethod } from 'vuex';

import { BASE_BLACK_COLOR } from '@/utils/constants';

interface DetailError {
  message: String;
}
interface DetailData {
  error: DetailError;
  expiresAt: String;
}
interface LoginResponseData {
  data: DetailData;
}
@Component({
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    ...mapMutations({
      setUser: 'auth/setUser',
      loading: 'auth/loading',
    }),
  },
})
export default class Login extends Vue {
  color = BASE_BLACK_COLOR;
  show = false;
  errorMessage: String = '';
  showAlert = false;
  password = '';
  email = '';
  passwordRules: Array<Function> = [];
  emailRules: Array<Function> = [];
  login!: ({ email, password, userAgent }) => Promise<LoginResponseData>;
  setUser!: MutationMethod;
  loading!: MutationMethod;

  onEmailFocus() {
    this.emailRules = [];
    this.showAlert = false;
  }
  onPasswordFocus() {
    this.passwordRules = [];
    this.showAlert = false;
  }

  submit() {
    this.emailRules = [
      (v) => !!v || 'E-mail muss ausgefüllt sein',
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-z]{2,}))$/;
        return pattern.test(v) || 'E-mail hat ein falsches Format';
      },
    ];
    this.passwordRules = [(v) => !!v || 'Passwort muss ausgefüllt sein'];
    setTimeout(async () => {
      //@ts-ignore
      if (this.$refs.form.validate()) {
        try {
          await this.login({
            email: this.email,
            password: this.password,
            userAgent: navigator.userAgent,
          }).then((res) => {
            if (res.data.error) {
              this.loading(false);
              this.errorMessage = `${res.data.error.message}`;
              this.showAlert = true;
            }
            if (!res.data.error) {
              this.$router.push('/');
              this.setUser(res.data);
            }
          });
        } catch (error) {
          console.log(error);
          this.errorMessage =
            'Nutzerdaten nicht korrekt. Bitte versuchen Sie es erneut.';
          this.showAlert = true;
        }
      }
    });
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      return this.submit();
    }
  }
  goForgotPassword() {
    this.$router.push('/forgot-password');
  }
}
